<template>
  <div class="home">
    <Nav></Nav>
    <div class="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div class="max-w-md w-full space-y-8">
        <div>
          <img class="mx-auto w-20" src="@/assets/images/logo.png" alt="Workflow">
          <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900">
            SIKIPEDIA
          </h2>
          <p class="mt-2 text-center text-sm text-gray-600">
            Bir am uğrunda ya'rab ne yiğitler koşuyor
          </p>
        </div>
        <div class="flex justify-center">
          <button type="submit" @click="createRoom" class="group relative w-60 flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
            <span class="absolute left-0 inset-y-0 flex items-center pl-3">
              <svg class="h-5 w-5 text-indigo-500 group-hover:text-indigo-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 28" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M18 12.5v1c0 0.281-0.219 0.5-0.5 0.5h-5.5v5.5c0 0.281-0.219 0.5-0.5 0.5h-1c-0.281 0-0.5-0.219-0.5-0.5v-5.5h-5.5c-0.281 0-0.5-0.219-0.5-0.5v-1c0-0.281 0.219-0.5 0.5-0.5h5.5v-5.5c0-0.281 0.219-0.5 0.5-0.5h1c0.281 0 0.5 0.219 0.5 0.5v5.5h5.5c0.281 0 0.5 0.219 0.5 0.5zM20 19.5v-13c0-1.375-1.125-2.5-2.5-2.5h-13c-1.375 0-2.5 1.125-2.5 2.5v13c0 1.375 1.125 2.5 2.5 2.5h13c1.375 0 2.5-1.125 2.5-2.5zM22 6.5v13c0 2.484-2.016 4.5-4.5 4.5h-13c-2.484 0-4.5-2.016-4.5-4.5v-13c0-2.484 2.016-4.5 4.5-4.5h13c2.484 0 4.5 2.016 4.5 4.5z" clip-rule="evenodd" />
              </svg>
            </span>
            Oda Oluştur
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { generateString } from '@/helpers/global.js';
import Nav from "../components/Nav";
export default {
  name: 'Home',
  components: {Nav},
  methods: {
    createRoom() {
      const room_code = generateString(40);
      this.$store.dispatch('createRoom', room_code)
        .then(() => {
          this.$router.push({ name: 'Room', params: { code: room_code } });
        });
    }
  }
}
</script>
