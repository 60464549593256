<template>
  <nav class="bg-gray-800 absolute w-full flex top-0 left-0">
    <div class="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 w-full">
      <div class="relative flex items-center justify-between h-16">
        <div class="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
          <div class="flex-shrink-0 flex items-center">
            <router-link :to="{ name: 'Home' }" class="flex items-center"> 
              <img class="hidden lg:block h-8 w-auto" src="@/assets/images/logo.png" alt="Sikipedia">
              <span class="text-white font-medium ml-3">SİKİPEDİA</span>
            </router-link>
          </div>
          <div class="hidden sm:block sm:ml-6">
            <div class="flex space-x-4">
              <router-link :to="{ name: 'Home' }" class="bg-gray-900 text-white px-3 py-2 rounded-md text-sm font-medium">Anasayfa</router-link>
              <a href="#" class="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium">Keşfet</a>
              <a href="#" class="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium">Odalar</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'Nav',
}
</script>