import firebase from 'firebase'
import 'firebase/firestore'

const firebaseConfig = {
  apiKey: "AIzaSyCjgdMMwATM1xm5q5491lwVY3_T1jd1euM",
  authDomain: "swiki-141da.firebaseapp.com",
  projectId: "swiki-141da",
  storageBucket: "swiki-141da.appspot.com",
  messagingSenderId: "114709309997",
  appId: "1:114709309997:web:40911190f49c7175ee26a4",
  measurementId: "G-CW4HMLTFP9"
}
firebase.initializeApp(firebaseConfig)

const db = firebase.firestore()

const roomsCollection = db.collection('rooms')
const usersCollection = db.collection('users')

export {
  db,
  roomsCollection,
  usersCollection
}