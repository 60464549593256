<template>
  <div id="app">
    <router-view/>
    <div class="absolute bottom-0 left-0 w-full flex justify-center py-4 text-gray-500 text-sm">
      created by <a href="https://twitter.com/muitogoxtosa" target="blank" class="mx-1 text-blue-500">@muitogoxtosa</a> with 🍆
    </div>
  </div>
</template>

<script>
export default {
  name: 'App'
}
</script>