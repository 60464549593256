import * as fb from '../service/firebase';
/* eslint-disable */

export default {
  createRoom({commit}, room_code) {
    return fb.roomsCollection.doc(room_code).set({
      code: room_code,
      users: [],
      currentPhoto: 0,
    });
  },
  createUser({commit}, user) {
    return fb.usersCollection.doc(user.code).set({
      code: user.code,
      name: user.name
    });
  },
  fetchRoom({commit}, code) {
    return fb.roomsCollection.doc(code);
  },
  addUserToRoom({commit}, { room, user }) {
    return fb.db.runTransaction((transaction) => {
      return transaction.get(fb.roomsCollection.doc(room)).then(doc => {
        if (!doc.exists) throw "Böyle bi oda yok aq";
        
        let users = doc.data().users;

        users.push({
          name: user.name,
          icon: user.icon,
          admin: user.admin
        });

        transaction.update(fb.roomsCollection.doc(room), {users});
      })
    });
  },
  addPhotoToRoom({commit}, {url, photo, code}) {
    return fb.roomsCollection.doc(code).collection('photos').add({
      url,
      photo,
      points: [],
      created_at: new Date().getTime()
    });
  },
  fetchPhotos({commit}, code) {
    return fb.roomsCollection.doc(code).collection('photos').orderBy("created_at", "asc");
  },
  changePhoto({commit}, {room, index}) {
    return fb.roomsCollection.doc(room).update({
      currentPhoto: index,
    });
  },

  fetchPoints({commit}, {photo, room}) {
    return fb.roomsCollection.doc(room).collection('photos').doc(photo).collection('points');
  },
  votePhoto({commit}, params) {
    const ref = fb.roomsCollection.doc(params.room).collection('photos').doc(params.photo);
    return fb.db.runTransaction((transaction) => {
      return transaction.get(ref).then(doc => {
        if (!doc.exists) throw "Böyle bi foto yok aq";
        
        let points = doc.data().points;

        let pointIndex = points.findIndex(point => point.user === params.user);
        if (pointIndex === -1) {
          points.push({
            user: params.user,
            point: params.point
          });
        }else {
          points[pointIndex].point = params.point;
        }

        transaction.update(ref, {points});
      });
    });
  },
}