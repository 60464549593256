export default {
  icons: [
    {icon: '💋'}, {icon: '😀'}, {icon: '😃'}, {icon: '😄'}, {icon: '😁'},
    {icon: '😆'}, {icon: '😅'}, {icon: '😂'}, {icon: '🤣'}, {icon: '😊'},
    {icon: '😇'}, {icon: '🙂'}, {icon: '🙃'}, {icon: '😉'}, {icon: '😌'},
    {icon: '😍'}, {icon: '🥰'}, {icon: '😘'}, {icon: '😗'}, {icon: '😙'},
    {icon: '😚'}, {icon: '😋'}, {icon: '😛'}, {icon: '😝'}, {icon: '😡'},
    {icon: '🤬'}, {icon: '🤯'}, {icon: '😳'}, {icon: '🥵'}, {icon: '🥶'},
    {icon: '😱'}, {icon: '😨'}
  ],
}